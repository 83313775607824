const operator_user_chinese_items = [
    {
        icon: 'mdi-truck',
        title: 'container.pack_box',
        to: '/tables/kontainer-box-list',
    },
    {
        icon: 'mdi-truck-delivery',
        title: 'container.container',
        to: '/tables/kontainer-list',
    },
    {
        icon: 'mdi-truck-delivery',
        title: 'container.pre-payment-list',
        to: '/tables/prepayment-list',
    },
    {
        icon: 'mdi-factory',
        title: 'supplier.supplier',
        to: '/tables/supplier-list',
    },
    {
        icon: 'mdi-gift',
        title: 'commodity.commodity',
        group: '/tables',
        children: [
            {
                title: 'commodity.list',
                to: 'commodity-list',
            },
            // {
            //     title: 'commodity.deliver-type',
            //     to: 'deliver-type-list',
            // },
            // {
            //     title: 'commodity.deliver-suject',
            //     to: 'deliver-suject-list',
            // },
            // {
            //     title: 'commodity.customs-type',
            //     to: 'customs-type-list',
            // },
            // {
            //     title: 'commodity.deliver-fee-combination',
            //     to: 'deliver-fee-list',
            // },
            // {
            //     title: 'tax.tax-primary-type',
            //     to: 'tax-primary-type-list',
            // },
            // {
            //     title: 'tax.tax-secondary-type',
            //     to: 'tax-secondary-type-list',
            // },
        ],
    },
    {
        icon: 'mdi-city',
        title: 'customer.customer',
        group: '/tables',
        children: [
            {
                title: 'customer.list',
                to: 'customer-list',
            },
        ],

    },
];
export {
    operator_user_chinese_items
};
