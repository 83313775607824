const operator_user_items = [
    {
        icon: 'mdi-truck',
        title: 'container.pack_box',
        to: '/tables/kontainer-box-list',
    },
    {
        icon: 'mdi-truck-delivery',
        title: 'container.container',
        to: '/tables/kontainer-list',
    },
    {
        icon: 'mdi-truck-delivery',
        title: 'container.deliver-fee-verify',
        to: '/tables/deliver-fee-verify-list',
    },
    {
        icon: 'mdi-truck-delivery',
        title: 'container.custom-deliver-fee',
        to: '/tables/custom-deliver-fee-list',
    },
    {
        icon: 'mdi-truck-delivery',
        title: 'container.custom-deliver-fee-after',
        to: '/tables/custom-deliver-fee-after-list',
    },
    {
        icon: 'mdi-truck-delivery',
        title: 'container.receive',
        to: '/tables/receive-list',
    },
    {
        icon: 'mdi-truck',
        title: 'container.invoice',
        to: '/tables/invoice-list',
    },
    {
        icon: 'mdi-gift',
        title: 'commodity.commodity',
        group: '/tables',
        children: [
            {
                title: 'commodity.list',
                to: 'commodity-list',
            },
            // {
            //     title: 'commodity.deliver-type',
            //     to: 'deliver-type-list',
            // },
            {
                // title: 'commodity.deliver-suject',
                title: 'commodity.deliver-type',
                to: 'deliver-suject-list',
            },
            {
                // title: 'commodity.deliver-suject',
                title: 'commodity.additional-fee',
                to: 'additional-fee-list',
            },
            {
                title: 'commodity.customs-type',
                to: 'customs-type-list',
            },
            // {
            //     title: 'commodity.deliver-fee-combination',
            //     to: 'deliver-fee-list',
            // },
            // {
            //     title: 'tax.tax-primary-type',
            //     to: 'tax-primary-type-list',
            // },
            // {
            //     title: 'tax.tax-secondary-type',
            //     to: 'tax-secondary-type-list',
            // },
        ],
    },
    {
        icon: 'mdi-factory',
        title: 'supplier.supplier',
        to: '/tables/supplier-list',
    },
    {
        icon: 'mdi-city',
        title: 'customer.customer',
        group: '/tables',
        children: [
            {
                title: 'customer.list',
                to: 'customer-list',
            },
            {
                title: 'customer.deliver-fee',
                to: 'customer-deliver-type-relation-list',
            },
        ],

    },
    {
        icon: 'mdi-factory',
        title: 'warehouse.warehouse',
        to: '/tables/warehouse-list',
    },
];
export {
    operator_user_items
};
