const account_admin_items = [
    {
        icon: 'mdi-truck',
        title: 'account.account',
        group: '/tables',
        children: [
            {
                title: 'account.list',
                to: 'account-list',
            },
            {
                title: 'account.role-list',
                to: 'role-list',
            },
        ]
    },
];
export {
    account_admin_items
};